<template>
  <section
    id="leaver"
    class="overflow-hidden"
  >
    <v-row
      justify="center"
      align="center"
      no-gutters
    >
      <v-col
        class="d-flex justify-center align-center py-10"
        cols="10"
      >
        <v-img
          :src="require('@/assets/logos/logo_icon.png')"
          max-height="100"
          contain
        />
      </v-col>
    </v-row>
  </section>
</template>
<script>
  export default {}
</script>
